import { useNavigate } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Page {
  href: string;
  label: string;
}

export const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [navClosed, setNavClosed] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState("top-[80px]");
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const pages: Page[] = [
    { href: "/", label: "Home" },
    { href: "/team", label: "Team" },
    { href: "/partners", label: "Partners" },
    { href: "/faq", label: "Faq" },
  ];
  const currentRoute = window.location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
        setDropdownPosition("top-[56px]");
      } else {
        setScrolled(false);
        setDropdownPosition("top-[80px]");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setNavClosed(!navClosed);
  };

  return (
    <nav
      className={twMerge(
        "fixed left-1/2 flex-row gap-3 top-0 w-full transform -translate-x-1/2 p-4 transition-all duration-300 ease-in-out z-50 flex items-center justify-between",
        scrolled || !navClosed
          ? "h-14 bg-[#141414] rounded-[20px] w-max top-9"
          : "h-20 bg-transparent"
      )}
    >
      <div
        className={twMerge(
          "flex items-center",
          scrolled ? "justify-center" : "justify-start"
        )}
        onClick={() => navigate("/")}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            navigate("/");
          }
        }}
        tabIndex={0}
        role="button"
      >
        <img
          src="/Graphics/pfp.png"
          alt="Phans.bio Logo"
          className="h-9 w-9 rounded-full cursor-pointer"
        />
        <span className="text-[#ffffffcd] font-semibold text-lg cursor-pointer">
          Phans.bio
        </span>
      </div>
      <div className="hidden md:flex space-x-5">
        {pages.map((page) => (
          <a
            key={page.href}
            href={page.href}
            onClick={() => navigate(page.href)}
            className={twMerge(
              "text-gray-400 hover:text-gray-100 transition-colors duration-200",
              currentRoute === page.href ? "text-red-600" : ""
            )}
          >
            {page.label}
          </a>
        ))}
      </div>
      <div className="md:hidden">
        <button className="text-white flex justify-center items-center">
          <label
            className="hamburger"
            htmlFor="nav-toggle"
            aria-label="Toggle Navigation"
          >
            <input
              ref={checkboxRef}
              onClick={(event) => {
                event.stopPropagation();
                toggleNav();
              }}
              type="checkbox"
              id="nav-toggle"
            />
            <svg viewBox="0 0 32 32">
              <path
                d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                className="line line-top-bottom"
              ></path>
              <path d="M7 16 27 16" className="line"></path>
            </svg>
          </label>
        </button>
      </div>
      <div
        className={twMerge(
          `absolute flex-row left-1/2 transform -translate-x-1/2 items-center justify-between space-x-3 whitespace-nowrap md:hidden p-4 bg-[#1c1c1c] rounded-lg shadow-lg border border-[#333] transition-all duration-300 ease-in-out ${dropdownPosition}`,
          navClosed ? "hidden" : "flex"
        )}
      >
        {pages.map((page) => (
          <a
            key={page.href}
            href={page.href}
            onClick={() => navigate(page.href)}
            className={twMerge(
              "text-gray-400 hover:text-gray-100 transition-colors duration-200",
              currentRoute === page.href ? "text-red-600" : ""
            )}
          >
            {page.label}
          </a>
        ))}
      </div>
    </nav>
  );
};
