import React, { useEffect, useState } from "react";
import { useNavigate } from "@remix-run/react";
import { Navbar } from "~/components/navbars/main";
import { ClientOnly } from "remix-utils/client-only";
import Footer from "./footer";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [origin, setOrigin] = useState<string>("");
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 200);
    };

    if (typeof window !== "undefined") {
      setOrigin(window.location.origin);
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="app relative flex min-h-screen flex-col justify-between">
      <ClientOnly>
        {() => {
          return <Navbar />;
        }}
      </ClientOnly>
      <main>{children}</main>
      <Footer />

      {showBackToTop && (
        <button
          className="fixed bottom-5 right-5 bg-secondary/80 text-white p-3 rounded-full shadow-lg transition-all duration-300 ease-in-out transform hover:scale-110 hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 group z-10"
          onClick={scrollToTop}
        >
          <span className="sr-only">Back to top</span>
          <i className="fas fa-arrow-up w-6 h-6"></i>
          <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
            Back to Top
          </span>
        </button>
      )}
    </div>
  );
};

export default Layout;
