import React from "react";
import { twMerge } from "tailwind-merge";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();
  const currentRoute = location.pathname;

  const links = [
    { href: "/", label: "Home" },
    { href: "/team", label: "Team" },
    { href: "/partners", label: "Partners" },
    { href: "/faq", label: "Faq" },
  ];

  return (
    <footer className="bg-main-color-grey">
      <div className="container mx-auto px-4 py-3">
        <ul className="flex flex-wrap justify-center gap-8">
          {links.map((link) => (
            <li key={link.href}>
              <a
                href={link.href}
                className={twMerge(
                  "rounded-md px-4 py-2 text-lg font-semibold text-white transition duration-300 ease-in-out",
                  currentRoute === link.href
                    ? "bg-primary/80"
                    : "hover:bg-primary/80"
                )}
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
